// @flow
import * as React from 'react'
import styled, { css } from 'styled-components'
import config, { createQuery } from './grid-config'
import { DIMENSION_NAMES, mediaQuery } from '../../styles/media'
import type { Columns } from './grid-config'
import type { DimensionNameTypes } from '../../types/enums/DimensionNameTypes'

type Align = 'left' | 'center' | 'right'
type VerticalAlign = 'top' | 'middle' | 'bottom' | 'stretch'

type Props = {
  xs?: Columns | boolean,
  sm?: Columns | boolean,
  md?: Columns | boolean,
  lg?: Columns | boolean,
  hd?: Columns | boolean,
  xsOffset?: Columns,
  smOffset?: Columns,
  mdOffset?: Columns,
  lgOffset?: Columns,
  hdOffset?: Columns,
  align?: Align,
  verticalAlign?: VerticalAlign,
  stretch?: boolean,
  shrink?: boolean,
  children?: React.Node,
}

/**
 * Takes a fraction number, and reduce it to a fixed precision, only if needed.
 * Removes all trailing 0's
 **/
const shortPrecision = (value: number, precision: number = 6) => {
  const precise = value.toFixed(precision)
  const match = precise.match(/(^\d+)\.(\d!0)?(0+)$/)
  if (match) {
    return `${match[1]}${match[2] ? `.${match[2]}` : ''}`
  }
  return precise
}

const renderBreakPoint = (
  columns?: number | boolean,
  dimension?: DimensionNameTypes,
) => {
  let result
  if (typeof columns === 'number') {
    result = css`
      flex-basis: ${shortPrecision((100 / config.gridSize) * columns)}%;
      max-width: ${shortPrecision((100 / config.gridSize) * columns)}%;
    `
  } else if (columns === true) {
    result = css`
      flex-grow: 1;
      flex-basis: 0;
      max-width: 100%;
    `
  }
  if (columns === false) {
    result = 'display: none;'
  }

  if (result) return dimension ? mediaQuery[dimension]`${result}` : result

  return null
}

const align = (align?: Align) => {
  if (!align) return null
  if (align === 'right')
    return css`
      display: flex;
      justify-content: flex-end;
    `
  if (align === 'center')
    return css`
      display: flex;
      justify-content: center;
    `
  if (align === 'left')
    return css`
      display: flex;
      justify-content: flex-start;
    `
}
const verticalAlign = (align?: VerticalAlign) => {
  if (!align) return null
  if (align === 'stretch')
    return css`
      display: flex;
      align-items: stretch;
    `
  if (align === 'top')
    return css`
      display: flex;
      align-items: flex-start;
    `
  if (align === 'middle')
    return css`
      display: flex;
      align-items: center;
    `
  if (align === 'bottom')
    return css`
      display: flex;
      align-items: flex-end;
    `
}

const offset = (key: DimensionNameTypes | boolean, columns?: Columns) => {
  if (columns) {
    return createQuery(
      key,
      css`
        margin-left: ${(100 / config.gridSize) * columns}%;
      `,
    )
  }
  return null
}

const Col: React.ComponentType<Props> = styled.div`
  box-sizing: border-box;
  flex: ${(p: Props) => (p.stretch ? '1' : '0')} ${(p: Props) =>
  p.shrink ? '1' : '0'} auto;
  
  ${(p: Props) => align(p.align)}
  ${(p: Props) => verticalAlign(p.verticalAlign)}
  ${(p: Props) => DIMENSION_NAMES.map(key => renderBreakPoint(p[key], key))}
  ${(p: Props) => offset('xs', p.xsOffset)}
  ${(p: Props) => offset('sm', p.smOffset)}
  ${(p: Props) => offset('md', p.mdOffset)}
  ${(p: Props) => offset('lg', p.lgOffset)}
  ${(p: Props) => offset('hd', p.hdOffset)}
`

Col.displayName = 'Col'

export default Col
