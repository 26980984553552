// @flow
import * as React from 'react'
import styled, { keyframes } from 'styled-components'

type Props = {
  className?: string,
  style?: Object,
  size?: number,
}

const load8 = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`

const LoaderWrapper = styled.div`
  height: ${({ size }) => (size ? `${size}px` : '22px')};
  width: ${({ size }) => (size ? `${size}px` : '22px')};
`

const Loader = styled.div`
  position: relative;
  text-indent: -9999em;
  border-top: ${({ theme }) => `2px solid ${theme.body}`};
  border-right: ${({ theme }) => `2px solid ${theme.borderPositive}`};
  border-bottom: ${({ theme }) => `2px solid ${theme.borderPositive}`};
  border-left: ${({ theme }) => `2px solid ${theme.borderPositive}`};
  transform: translateZ(0);
  animation: ${load8} 1.1s infinite linear;

  border-radius: 50%;
  width: 100%;
  height: 100%;
`

const Spinner = (props: Props) => (
  <LoaderWrapper {...props}>
    <Loader />
  </LoaderWrapper>
)

Spinner.displayName = 'Spinner'
Spinner.defaultProps = {}

export default Spinner
