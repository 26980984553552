// @flow
const months = [
  'januar',
  'februar',
  'marts',
  'april',
  'maj',
  'juni',
  'juli',
  'august',
  'september',
  'oktober',
  'november',
  'december',
]

// split the date into its relevant part,
// used to construct the date formats
const getDateParts = (date: Date) => ({
  day: `0${date.getDate()}`.slice(-2),
  month: `0${date.getMonth() + 1}`.slice(-2),
  fullMonth: months[date.getMonth()],
  year: date.getFullYear(),
})

// helper to validate the timestamp and call formatting callback function
// with the date parts object
const parseDate = (unixTimestamp: number, callback: Function) => {
  const date = new Date(unixTimestamp * 1000) // convert to milliseconds
  if (isNaN(date.getTime())) {
    console.log('Invalid date given', unixTimestamp) // eslint-disable-line no-console
    return ''
  }
  return callback(getDateParts(date))
}

// DD-MM-YYYY
export const shortDate = (unixTimestamp: number) =>
  parseDate(unixTimestamp, (dateParts: Object) => {
    const { day, month, year } = dateParts
    return [day, month, year].join('-')
  })

// DD. MMMM, YYYY
export const dateWithFullMonth = (unixTimestamp: number) =>
  parseDate(unixTimestamp, (dateParts: Object) => {
    const { day, fullMonth, year } = dateParts
    return `${day}. ${fullMonth}, ${year}`
  })

// MM DD, YYYY
export const twitterDateFormat = (unixTimestamp: number) =>
  parseDate(unixTimestamp, (dateParts: Object) => {
    const { day, fullMonth, year } = dateParts
    const shortenedMonth = fullMonth.slice(0, 3)
    return `${shortenedMonth} ${day}, ${year}`
  })

let controller
let signal
export const getCurrentDate = async ({
  continent = 'Europe',
  city,
}: Object) => {
  if (city) {
    if (controller !== undefined) {
      // Cancel the previous request
      controller.abort()
    }

    // Feature detect
    if ('AbortController' in window) {
      controller = new AbortController()
      signal = controller.signal
    }

    let currentDate = new Date()
    const timeApiUrl = `https://worldtimeapi.org/api/timezone/${continent}/${city}`

    await fetch(timeApiUrl, { signal })
      .then(res => res.json())
      .then(data => {
        if (data && data.datetime && typeof data.datetime === 'string') {
          currentDate = new Date(data.datetime.split('.')[0])

          if (isNaN(currentDate.getHours())) {
            currentDate = new Date()
          }
        }

        return currentDate
      })
      .catch(err => {
        if (err.name === 'AbortError') {
          console.error('ABORTED CURRENT DATE')
        }
      })

    return currentDate
  } else {
    return new Date()
  }
}

// find number of days between two dates and check if it's more or less than "numberOfDays"
export const diffBetweenTwoDates = (
  unixTimestamp: string,
  numberOfDays: number,
) => {
  const date = new Date(parseInt(unixTimestamp) * 1000)
  const todaysDate = new Date(Date.now())

  // one day in ms
  const oneDay = 1000 * 60 * 60 * 24

  // find time difference between two dates
  const diffInTime = todaysDate.getTime() - date.getTime()

  // calculate number of days
  const diffInDays = Math.round(diffInTime / oneDay)

  return diffInDays > numberOfDays
}
