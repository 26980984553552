// @flow
import * as React from 'react'
import { ConfigContext } from '../../App/AppShell'

/* eslint-disable no-param-reassign */
export const imageDimensionMap = (
  width: number,
  height: number,
): { w: number, h: number } => {
  const aspect = height / width
  const normalizedWidth = Math.ceil(width)
  const normalizedHeight = Math.ceil(aspect * normalizedWidth)

  return {
    w: normalizedWidth,
    h: normalizedHeight,
  }
}

function imageQuality(dpr: number = 1) {
  return dpr >= 3 ? 30 : dpr === 2 ? 40 : undefined
}

function constructUrl(src, params) {
  const preparedParams = Object.keys(params)
    .filter(k => typeof params[k] !== 'undefined')
    .map(k => `${k}=${encodeURIComponent(params[k])}`)
    .join('&')

  return `${src}?${preparedParams}`
}

/**
 * Construct a URL for an image with an Imgix proxy, expanding image options
 * per the [API reference docs](https://www.imgix.com/docs/reference).
 * @param  {String} src         src of raw image
 * @param  {Object} options map of image API options, in long or short form per expansion rules
 * @return {String}             URL of image src transformed by Imgix
 */
function processImage(src: string, options: Object = {}): string {
  if (!src) {
    return ''
  }
  if (!src.includes('imgix.net')) return src

  return constructUrl(src, {
    auto: 'format',
    dpr: global.devicePixelRatio || 1,
    q: imageQuality(global.devicePixelRatio),
    ...options,
  })
}

export default processImage

export function withImgix(Component: React.ComponentType<any>) {
  class InjectedComponent extends React.Component<any> {
    render() {
      // todo: maybe check if already has protocol and domain
      if (process.env.NODE_ENV === 'development') {
        return <Component {...this.props} />
      }

      return (
        <ConfigContext.Consumer>
          {config => (
            <Component
              {...this.props}
              src={
                config && config.imgixDomain
                  ? `${config.imgixDomain}${this.props.src}`
                  : this.props.src
              }
            />
          )}
        </ConfigContext.Consumer>
      )
    }
  }

  return InjectedComponent
}
