// @flow
import * as React from 'react'
import withHover from '../hoc/withHover'

const CircleArrowRightIcon = ({ style, ...rest }: Object) => (
  <svg
    viewBox="0 0 24 24"
    {...rest}
    width="2em"
    height="2em"
    style={{ cursor: 'pointer', ...style }}
  >
    <g
      fill="currentColor"
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth="2"
    >
      <line fill="none" x1="6" x2="17" y1="12" y2="12" />
      <polyline fill="none" points=" 13,8 17,12 13,16 " />
      <circle cx="12" cy="12" fill="none" r="11" stroke="currentColor" />
    </g>
  </svg>
)

export default withHover<React.Config<Object, {}>>(CircleArrowRightIcon)
