// @flow
import * as React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import consecutive from 'consecutive'

import type { RelatedListItemViewModel } from '../../types/RelatedListItemViewModel'

import { getLineHeight, getFontSize } from '../../styles/style-helpers'
import { color, effects } from '../../styles/theme'
import { durations } from '../../styles/animations'

import Image from '../../components/Image/Image'
import Heading from '../../components/Heading/Heading'
import Paragraph from '../../components/Paragraph/Paragraph'
import { Link } from '../Link/Link'
import CircleArrowRightIcon from '../../icons/CircleArrowRightIcon'
import { diffBetweenTwoDates } from '../../utils/date'
import { HyphenatedText } from '../HyphenatedText/HyphenatedText'

type Props = RelatedListItemViewModel & {
  theme?: string,
  hasModuleHeader?: boolean,
}

type State = {}

const keyGen = consecutive()

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: calc(100% - ${rem(20)});
  margin-bottom: ${rem(20)};
  overflow: hidden;
  border-radius: ${({ transparent, theme }) =>
    transparent ? 0 : rem(theme.borderRadius)};
  border-bottom: ${({ transparent, theme }) =>
    transparent ? effects(theme).borderPositive : '0 none'};
  background: ${({ negative, theme }) =>
    negative ? theme.cardNegativeBackground : theme.cardPositiveBackground};
  ${({ transparent }) => (transparent ? `background: transparent` : null)};

  transition: transform ${durations.fast} ease-in-out;

  &:hover {
    transform: translateY(-3px);
  }

  &:hover img {
    transform: scale(1.1);
  }

  &:hover svg {
    transition: ${effects().linkHoverTransition};
    color: ${color.accent};
  }
`

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: ${({ transparent }) =>
    transparent ? `0 0 ${rem(38)}` : `${rem(64)} ${rem(40)} ${rem(40)}`};
  ${({ image }) => (image ? 'border-top: 0;' : '')};

  ${({ image }) =>
    !!image &&
    `
    padding-top: ${rem(48)};
  `}

  ${({ theme }) =>
    (theme.name === 'kids' || theme.name === 'lgbt') &&
    `
    padding: ${rem(36)} ${rem(40)};
  `}

  > :last-child {
    margin-bottom: 0;
  }
`

const StyledImage = styled(Image)`
  position: relative;
  max-width: 100%;
  padding-top: 75%;
  object-fit: contain;
  height: auto;
`

const StyledSubtitleWrapper = styled.div`
  margin-bottom: ${rem(12)};

  /* these 3 lines enforce ellipsis effect when text is too wide */
  display: table;
  table-layout: fixed;
  width: 100%;
`

const StyledSubtitle = styled.span`
  font-size: ${getFontSize('tiny')};
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${({ theme }) => theme.primary};
`

const StyledDate = styled(StyledSubtitle)`
  font-size: ${rem(11)};
  opacity: 0.85;
  margin-bottom: ${rem(18)};
`

const StyledOldDate = styled(StyledSubtitle)`
  font-size: ${rem(11)};
  color: ${({ theme }) => theme.oldDate};
`

const StyledParagraph = styled(Paragraph)`
  margin-bottom: ${rem(32)};
  color: ${props => props.theme.text};

  && {
    line-height: ${getLineHeight('normal')};
  }
`

const LinkIconWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  color: ${({ theme }) => theme.primary};
`

const StyledLink = styled(Link)`
  cursor: pointer;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
`

const TitleLink = styled(Link)`
  cursor: pointer;
  line-height: ${getLineHeight('small')};
`

class ListCard extends React.Component<Props, State> {
  static displayName = 'ListCard'
  static defaultProps = {}

  render() {
    const {
      image,
      title,
      description,
      link,
      date,
      displayDate,
      pretitle,
      negative,
      transparent,
      titleLink,
      isNewsItem,
      hasModuleHeader,
    } = this.props
    const theme = this.props.theme
    const hyphenatedTitle = <HyphenatedText text={title} minWordLength={4} />
    return (
      <Card
        link={link}
        negative={negative}
        theme={theme}
        transparent={transparent}
      >
        {image && <StyledImage cover fillContainer zoomOnHover {...image} />}

        <StyledWrapper image={image} transparent={transparent}>
          {pretitle && (
            <StyledSubtitleWrapper>
              <StyledSubtitle
                key={`ListCardPreTitle-${keyGen()}`}
                title={pretitle}
              >
                {pretitle}
              </StyledSubtitle>
            </StyledSubtitleWrapper>
          )}

          {title && (
            <Heading
              tag={hasModuleHeader ? 'h3' : 'h2'}
              level={3}
              responsiveSize
            >
              {titleLink ? (
                <TitleLink {...link}>{hyphenatedTitle}</TitleLink>
              ) : (
                hyphenatedTitle
              )}
            </Heading>
          )}

          {date && (
            <StyledDate>
              {displayDate &&
              isNewsItem &&
              diffBetweenTwoDates(displayDate, 90) ? (
                <StyledOldDate>Ældre nyhed: </StyledOldDate>
              ) : null}
              {date}
            </StyledDate>
          )}

          {description && <StyledParagraph>{description}</StyledParagraph>}

          {link && link.href && (
            <>
              <StyledLink {...link} />
              <LinkIconWrapper>
                <CircleArrowRightIcon />
              </LinkIconWrapper>
            </>
          )}
        </StyledWrapper>
      </Card>
    )
  }
}

export default ListCard
