// @flow
import type { ImageRatio } from './types'

const supports = {}

/**
 * Detect if objectFit is supported on the client
 * @type {boolean}
 */
export function objectFitSupport(): boolean {
  if (!supports.objectFit) {
    supports.objectFit = global.document
      ? global.document.body.style.objectFit !== undefined
      : true
  }

  return supports.objectFit
}

export default {
  objectFitSupport,
}

export function generateSrc(
  url: string,
  width: number,
  ratio?: ImageRatio = 'original',
) {
  if (url && !url.includes('/static/')) {
    const pos = url.lastIndexOf('/')
    return `${url.substr(0, pos)}/${ratio}/${width}/${url.substr(pos + 1)}`
  }

  return `${url}?w=${width}&ratio=${ratio || ''}`
}
