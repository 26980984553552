// @flow
import * as React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'

import { getLineHeight } from '../../styles/style-helpers'

import Heading from '../../components/Heading/Heading'
import Col from '../../components/Grid/Col'
import Row from '../../components/Grid/Row'
import Spinner from '../../components/Spinner/Spinner'

type Props = {
  title?: string,
  children?: React.Node,
  isLoading?: boolean,
  error?: boolean,
  itemsPerColumn?: 4 | 3 | 2 | 1,
}

type State = {}

const StyledHeading = styled(Heading).attrs({
  tag: 'h2',
  level: 2,
  marginBottom: 'large',
  responsiveSize: true,
})`
  && {
    line-height: ${getLineHeight('normal')};
  }
`

const StyledHeadingRow = styled.div`
  margin: 0 0 ${rem(28)};
  min-height: 1px;
`

const StyledCol = styled(Col)`
  margin-bottom: ${rem(20)};
`

const SpinnerWrapper = styled.div`
  position: relative;
  height: ${rem(200)};
  margin-bottom: ${rem(40)};
`

class ListGroup extends React.Component<Props, State> {
  static displayName = 'ListGroup'
  static defaultProps = {}

  getColumnSize() {
    const { itemsPerColumn } = this.props
    switch (itemsPerColumn) {
      case 1:
        return { xs: 12, sm: 12, md: 12 }
      case 2:
        return { xs: 12, sm: 6, md: 6 }
      case 3:
        return { xs: 12, sm: 6, md: 4 }
      default:
        return { xs: 12, sm: 6, md: 3 }
    }
  }

  render() {
    const { title, children, isLoading, error } = this.props
    const columns = this.getColumnSize()

    return (
      <React.Fragment>
        {title && (
          <StyledHeadingRow>
            <React.Fragment>
              <StyledHeading>{title}</StyledHeading>
            </React.Fragment>
          </StyledHeadingRow>
        )}

        {error && <div>Der skete en fejl. Prøv at genindlæse siden.</div>}

        {!error && isLoading && (
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
        )}

        {!error && !isLoading && (
          <Row mdGutter={4} smGutter={2}>
            {React.Children.map(children, (child, itemIndex) => (
              <StyledCol key={`group-item-${itemIndex}`} {...columns}>
                {child}
              </StyledCol>
            ))}
          </Row>
        )}
      </React.Fragment>
    )
  }
}

export default ListGroup
