// @flow
import React from 'react'
import { hyphenate } from '../../utils/hyphenation'

type HyphenatedTextProps = {
  text?: string,
  minWordLength?: number,
}

export function HyphenatedText({ text, minWordLength }: HyphenatedTextProps) {
  return React.useMemo(
    () => (
      <>
        {text
          ? hyphenate(text, minWordLength ? { minWordLength } : undefined)
          : null}
      </>
    ),
    [text, minWordLength],
  )
}
