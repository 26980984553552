// @flow
import createHyphenation from 'hyphen'
import patterns from 'hyphen/patterns/da'

export const defaultMinWordLength = 15

/**
 * Use the hyphenate function, to split long words with break characters.
 */
export const hyphenate = createHyphenation(patterns, {
  async: false,
  minWordLength: defaultMinWordLength,
})

/**
 * Use the hyphenateHTML function, to split long words within HTML text.
 */
export const hyphenateHTML = createHyphenation(patterns, {
  async: false,
  minWordLength: defaultMinWordLength,
  html: true,
})
