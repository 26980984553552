// @flow
import React, { PureComponent } from 'react'
import Measure from 'react-measure'
import styled from 'styled-components'

import type { ImageViewModel } from '../../types/ImageViewModel'
import type { Bounds, Rect } from './types'

import processImage, { imageDimensionMap, withImgix } from './imgix-support'
import ImgElement from './ImgElement'

const Container = styled.div`
  position: relative;
  width: 100%;
  ${({ fillContainer }) =>
    fillContainer &&
    `
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
  `} ${({ aspect }) =>
    aspect &&
    `
    padding-bottom: ${aspect * 100}%;
  `};
  ${({ zoomOnHover }) =>
    zoomOnHover &&
    `
    overflow: hidden
  `}
`

type Props = ImageViewModel & {
  onResize?: (bounds: Bounds) => void,
  className?: string,
  zoomOnHover?: boolean,
}

type State = {
  animatedIn: boolean,
  ready: boolean,
  elementWidth: number,
  elementHeight: number,
}

/**
 * Image handles fetching the correct Imgix image, and rendering it onto the page.
 * By default it will load an image that fillContainers the width of it's container, while keeping the original image aspect ratio
 */
class Image extends PureComponent<Props, State> {
  static displayName = 'Image'
  static defaultProps = {
    maxAspectRatio: 2,
    width: 1,
    height: 1,
  }

  static generateAspectRatio(
    width: number = 1,
    height: number = 1,
    maxAspectRatio: number = 1,
    aspectRatio?: number,
  ) {
    if (aspectRatio) {
      return aspectRatio
    }
    return Math.min(maxAspectRatio, height / width)
  }

  static generateImgixProps(props: Props) {
    return {
      fit: props.contain ? 'clip' : 'crop',
      crop: !props.contain
        ? props.focalPoint
          ? 'focalpoint'
          : props.crop
        : undefined,
      auto: ['compress', 'format'],
      'fp-x': props.focalPoint ? props.focalPoint.x : undefined,
      'fp-y': props.focalPoint ? props.focalPoint.y : undefined,
      ...props.imgixParams,
    }
  }

  static generateSource(
    src: string,
    width: number,
    height: number,
    imgixProps?: Object,
  ) {
    if (width > 0 && height > 0) {
      return processImage(src, {
        ...imgixProps,
        ...imageDimensionMap(width, height),
      })
    }

    return null
  }

  static generateBlurredSource(
    src: string,
    width: number = 1,
    height: number = 1,
    imgixProps: Object = {},
  ) {
    return processImage(src, {
      ...imgixProps,
      w: Math.ceil(64),
      h: Math.ceil((height / width) * 64),
      dpr: 1,
      q: 50,
      blur: 64,
    })
  }

  state = {
    ready: false,
    animatedIn: false,
    elementWidth: 0,
    elementHeight: 0,
  }

  timeout = null

  componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout)
  }

  handleLoad = () => {
    if (this.props.fadeIn) {
      this.setState({ ready: true })
      this.timeout = setTimeout(() => {
        this.timeout = null
        this.setState({ animatedIn: true })
      }, 1000)
    } else {
      this.setState({ ready: true, animatedIn: true })
    }
  }

  handleTransitionEnd = () => {
    if (this.state.ready) {
      this.setState({ animatedIn: true })
    }
  }

  handleError = () => {
    this.setState({ ready: true, animatedIn: true })
  }

  handleMeasure = (contentRect: Rect) => {
    this.setState({
      elementWidth: Math.ceil(contentRect.bounds.width),
      elementHeight: Math.ceil(contentRect.bounds.height),
    })

    if (this.props.onResize) this.props.onResize(contentRect.bounds)
  }

  render() {
    const {
      src,
      fadeIn,
      disableBlur,
      maxAspectRatio,
      fillContainer,
      contain,
      cover,
      alignX,
      alignY,
      width,
      height,
      alt,
      className,
      zoomOnHover,
      aspectRatio,
    } = this.props

    const { ready, animatedIn, elementWidth, elementHeight } = this.state
    const imgixProps = Image.generateImgixProps(this.props)
    const currentSrc = Image.generateSource(
      src,
      elementWidth,
      elementHeight,
      imgixProps,
    )

    const aspect = !fillContainer
      ? Image.generateAspectRatio(width, height, maxAspectRatio, aspectRatio)
      : null

    return (
      <Measure bounds onResize={this.handleMeasure}>
        {({ measureRef }) => {
          return (
            <Container
              className={className}
              ref={measureRef}
              fillContainer={fillContainer}
              aspect={aspect}
              zoomOnHover={zoomOnHover}
            >
              {!disableBlur && fadeIn && !animatedIn ? (
                <ImgElement
                  src={Image.generateBlurredSource(
                    src,
                    width,
                    height,
                    imgixProps,
                  )}
                  contain={contain}
                  cover={cover}
                  blurred={true}
                  alignX={alignX}
                  alignY={alignY}
                  zoomOnHover={zoomOnHover}
                  aria-hidden
                />
              ) : null}
              {currentSrc || (!fadeIn && !disableBlur) ? (
                <ImgElement
                  src={
                    currentSrc ||
                    Image.generateBlurredSource(src, width, height, imgixProps)
                  }
                  onLoad={elementWidth && !ready ? this.handleLoad : undefined}
                  onError={
                    elementWidth && !ready ? this.handleError : undefined
                  }
                  onTransitionEnd={
                    fadeIn ? this.handleTransitionEnd : undefined
                  }
                  blurred={!currentSrc}
                  show={fadeIn && !animatedIn && ready}
                  hide={fadeIn && !animatedIn && !ready}
                  contain={contain}
                  cover={cover}
                  alignX={alignX}
                  alignY={alignY}
                  alt={alt}
                  zoomOnHover={zoomOnHover}
                />
              ) : null}
            </Container>
          )
        }}
      </Measure>
    )
  }
}

export default withImgix(Image)
