// @flow
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { objectFitSupport } from './helpers'

function getObjectPosition(alignX, alignY) {
  return alignX || alignY
    ? `${alignX || 'center'} ${alignY || 'center'}`
    : undefined
}

const Img = styled.img`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  max-width: none;
  object-fit: cover;
  object-position: center;
  ${({ blurred }) =>
    blurred &&
    `
    opacity: 0.4;
  `}

  ${({ show }) =>
    show &&
    `
    transition: opacity 0.4s linear;
    will-change: opacity;
    opacity: 1;
  `}

  ${({ hide }) =>
    hide &&
    `
    opacity: 0;
    will-change: opacity;
  `}

  ${({ zoomOnHover }) =>
    zoomOnHover &&
    `
    transition: transform 2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    &:hover {
      transform: scale(1.1);
    }
  `} 
`

type Props = {
  src: string,
  alignX?: 'left' | 'center' | 'right',
  alignY?: 'top' | 'center' | 'bottom',
  alt?: string,
  contain?: boolean,
  cover?: boolean,
  onLoad?: Function,
  blurred?: boolean,
  show?: boolean,
  hide?: boolean,
  zoomOnHover?: boolean,
}

/**
 * Img Element with ObjectFit fallback in unsupported browsers (IE)
 */
function ImgElement({
  src,
  alt,
  contain,
  cover,
  alignX,
  alignY,
  onLoad,
  blurred,
  ...props
}: Props) {
  /**
   * If this Image requires unsupported ObjectFit, fallback to a <div> with backgroundImage
   **/
  const [renderAsDiv, setRenderAsDiv] = useState(false)
  useEffect(() => {
    setRenderAsDiv(
      (alignX || alignY || contain || cover) && !objectFitSupport(),
    )
  }, [])

  if (renderAsDiv) {
    if (onLoad) {
      // Trigger the onLoad - could create a dummy image instead that triggers it after the image is actually loaded.
      onLoad()
    }
    return (
      <Img
        as="div"
        blurred={blurred}
        style={{
          backgroundImage: `url(${src})`,
          backgroundSize: contain ? 'contain' : cover ? 'cover' : undefined,
          backgroundPosition: cover
            ? 'center center'
            : getObjectPosition(alignX, alignY),
        }}
        {...props}
      />
    )
  }

  return (
    <Img
      src={src}
      blurred={blurred}
      style={{
        objectPosition: getObjectPosition(alignX, alignY),
        objectFit: contain ? 'contain' : undefined,
      }}
      onLoad={onLoad}
      alt={alt || ''}
      role={!alt ? 'presentation' : undefined}
      {...props}
    />
  )
}

ImgElement.defaultProps = {
  contain: false,
}

export default ImgElement
