// @flow
import * as React from 'react'
import styled, { css } from 'styled-components'
import { createQuery } from './grid-config'
import Col from './Col'
import type { DimensionNameTypes } from '../../types/enums/DimensionNameTypes'
import { rem } from 'polished'

type Props = {
  children: React.ChildrenArray<?React.Element<typeof Col>>,
  /** Gutter in 10px increments */
  xsGutter?: number,
  smGutter?: number,
  mdGutter?: number,
  lgGutter?: number,
  hdGutter?: number,
  noWrap?: boolean,
  reverse?: boolean | DimensionNameTypes | Array<DimensionNameTypes>,
  start?: boolean | DimensionNameTypes | Array<DimensionNameTypes>,
  center?: boolean | DimensionNameTypes | Array<DimensionNameTypes>,
  end?: boolean | DimensionNameTypes | Array<DimensionNameTypes>,
  top?: boolean | DimensionNameTypes | Array<DimensionNameTypes>,
  middle?: boolean | DimensionNameTypes | Array<DimensionNameTypes>,
  bottom?: boolean | DimensionNameTypes | Array<DimensionNameTypes>,
  around?: boolean | DimensionNameTypes | Array<DimensionNameTypes>,
  between?: boolean | DimensionNameTypes | Array<DimensionNameTypes>,
  first?: boolean | DimensionNameTypes | Array<DimensionNameTypes>,
  last?: boolean | DimensionNameTypes | Array<DimensionNameTypes>,
}

function addGutter(key: DimensionNameTypes | boolean, gutter?: number) {
  if (gutter) {
    const gutterVal = rem((gutter * 10) / 2)
    const gutterValOuter = rem((gutter * 10) / 2 - 1)

    return createQuery(
      key,
      css`
        margin-right: -${gutterValOuter};
        margin-left: -${gutterValOuter};

        > ${Col} {
          padding-right: ${gutterVal};
          padding-left: ${gutterVal};
        }
      `,
    )
  }
  return null
}

const Row: React.ComponentType<Props> = styled.div`
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: ${(p: Props) => (p.noWrap ? 'nowrap' : 'wrap')};
  
  ${(p: Props) => addGutter('xs', p.xsGutter)}
  ${(p: Props) => addGutter('sm', p.smGutter)}
  ${(p: Props) => addGutter('md', p.mdGutter)}
  ${(p: Props) => addGutter('lg', p.lgGutter)}
  ${(p: Props) => addGutter('hd', p.hdGutter)}
  ${(p: Props) =>
    createQuery(
      p.reverse,
      css`
        flex-direction: row-reverse;
      `,
    )}

  ${(p: Props) =>
    createQuery(
      p.start,
      css`
        justify-content: flex-start;
      `,
    )}

  ${(p: Props) =>
    createQuery(
      p.center,
      css`
        justify-content: center;
      `,
    )}

  ${(p: Props) =>
    createQuery(
      p.end,
      css`
        justify-content: flex-end;
      `,
    )}

  ${(p: Props) =>
    createQuery(
      p.top,
      css`
        align-items: flex-start;
      `,
    )}

  ${(p: Props) =>
    createQuery(
      p.middle,
      css`
        align-items: center;
      `,
    )}

  ${(p: Props) =>
    createQuery(
      p.bottom,
      css`
        align-items: flex-end;
      `,
    )}

  ${(p: Props) =>
    createQuery(
      p.around,
      css`
        justify-content: space-around;
      `,
    )}

  ${(p: Props) =>
    createQuery(
      p.between,
      css`
        justify-content: space-between;
      `,
    )}

  ${(p: Props) =>
    createQuery(
      p.first,
      css`
        order: -1;
      `,
    )}

  ${(p: Props) =>
    createQuery(
      p.last,
      css`
        order: 1;
      `,
    )}
`

Row.displayName = 'Row'

export default Row
